import React from 'react';
import { Link } from 'gatsby';
import withContext from 'store/withContext';

// import Button from 'components/Global/Button';

import PageWrapper from '../styles/leagues.css.js';

const Leagues = (props) => {

  return (
    <PageWrapper>
      <main className="content">
        <section className="header">
          <div className="sub-heading">What Is The</div>
          <div className="heading">Smite Pro League</div>
          <div className="links-wrapper">
            <Link to="/league">Format & Rules</Link>
            <Link to="/teams/">League Teams</Link>
            <a href="https://twitch.tv/smitegame">Where To Watch</a>
          </div>
        </section>
        <div className="center-wrapper">
          <section className="about">
            <div className="copy-wrapper">
              <h6>About the SMITE Pro League</h6>
              <p>The SMITE Pro League is our elite competitive circuit on the Battleground of the Gods. It features eight of the top-performing professional teams in the world, and showcases the best mechanical talent that SMITE has to offer.</p>
              <p>All of our SPL teams have relocated to Georgia for Season 9 so they can live together, scrim together, and play their weekly matches live on LAN from the Hi-Rez Productions studio in Alpharetta, GA. With the added stability of year-long salaries and guaranteed housing, the leading pro SMITE players from all over the globe can focus on their year-round fight for gain and glory on the Battleground of the Gods.</p>
              <p>At the end of the year, their SPL journey will culminate in one ultimate battle at the SMITE World Championship. Several teams will enter, but only one will emerge victorious and lift the coveted hammer as the true gods of SMITE.</p>
            </div>
            <div className="image-wrapper">
              <img src="https://webcdn.hirezstudios.com/esports/assets/what-is-1.png" alt="SMITE Champions" />
            </div>
          </section>
          <section className="watch">
            <div className="copy-wrapper">
              <h6>Where To Watch</h6>
              <p>The SMITE Pro League is broadcast every weekend on our official SmiteGame streaming channels -- including <a href="https://www.twitch.tv/smitegame" target="_blank">Twitch</a>, <a href="https://www.youtube.com/smitegame" target="_blank">YouTube</a>, and even <a href="" target="_blank">Steam!</a></p>
              <strong>2022 SEASON MATCH SCHEDULE</strong>
              <div className="schedule-container">
                <div><strong>Friday</strong>Starting at 1:00 PM ET</div>
                <div><strong>Saturday</strong>Starting at 1:00 PM ET</div>
                <div><strong>Sunday</strong>Starting at 11:00 AM ET</div>
              </div>
              <Link className="button" to="/schedule">View Full Schedule</Link>
            </div>
            <div className="image-wrapper">
              <img src="https://webcdn.hirezstudios.com/esports/assets/what-is-2.png" alt="Splyce" />
            </div>
          </section>
          <section className="format">
            <strong>2022 LEAGUE FORMAT</strong>
            <p>The SMITE Pro League season is broken into three phases, with two cross-league Masters events at the end of Phases 1 and 2. At the end of the season, teams will also compete in a qualifier round for a seat in the pinnacle event of the year -- the SMITE World Championship.</p>
            <p>In Season 9, the SPL will use a points-based system that awards each team points based on where they placed at the end of Phase 2 and Phase 3. At the end of the season, the total points a team has will determine their seeding in the SMITE World Championship Qualifiers.</p>
            <strong>REGULAR SEASON PLAY</strong>
            <p>Each phase in the SMITE Pro League will follow a double round robin format with best-of-three matches. The regular season is split into three phases:</p>
            <div className="phase-container">
              <div><strong>• Phase 1 --</strong> April 1 - April 24, 2022</div>
              <div><strong>• Phase 2 --</strong> July 10 - July 31, 2022</div>
              <div><strong>• Phase 3 --</strong> August 26 - October 23, 2022</div>
            </div>
            <strong>SMITE MASTERS</strong>
            <p>Both of this year’s SMITE Masters events will be two-weeks long, cross-league LANs. Masters will follow 16 teams from both the SMITE Challenger Circuit (four from NA, four from EU) and the SMITE Pro League (all 8 teams).</p>
            <div className="phase-container">
              <div><strong>• Spring SMITE Masters --</strong> May 20 - May 29, 2022</div>
              <div><strong>• Summer SMITE Masters --</strong> August 5 - August 14, 2022</div>
            </div>
            <p>More details and information on the SMITE Masters events will be announced later this season.</p>
            <strong>SMITE WORLD CHAMPIONSHIP</strong>
            <p>This is the pinnacle event of the SMITE Pro League. This year's SMITE World Championship will be held in Arlington, Texas January 13th-15th 2023 at the Esports Stadium Arlington. <a href="https://www.smitegame.com/swc/" target="_blank" rel="noreferrer">Go here</a> for the schedule and more info on the event!</p>
            <p>In this three-day event, the best of the best SMITE Pro League teams will meet on the World Championship stage to fight for the right to lift the Hammer trophy -- the highest honor on the Battleground.</p>
          </section>
          <section className="teams" id="teamsSection">
            <div className="teams-container">
              <div className="team-heading">Smite Challenger Circuit 2022 Teams</div>
              {props.context.teams && props.context.teams.map(team => 
              <Link className="team" to={`/teams/${team.tag.toLowerCase()}`} key={team.name}><img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${team.tag}-70x70.png`} alt={team.name} /> {team.name}</Link>
              )}
            </div>
          </section>
        </div>
      </main>
    </PageWrapper>
  );
};

export default withContext(Leagues);

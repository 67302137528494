import styled from 'styled-components';

const PageWrapper = styled.div`
  padding-bottom: 2rem;
  @media screen and (max-width: 768px) {
    padding-bottom: initial;
  }
  .content {
    background: var(--background);
    box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    margin: 1.5rem auto 0 auto;
    max-width: 156.6rem;
    width: 100%;
    @media screen and (max-width: 768px) {
      margin-top: initial;
    }
    .center-wrapper {
      margin: 0 auto;
      max-width: 75.8rem;
      width: 100%;
      section {
        &:not(:last-of-type) {
          border-bottom: .1rem solid var(--primary);
        }
        padding: 6rem 0 5rem 0;
      }
    }
    h6 {
      color: var(--text);
      font-family: var(--font-body);
      font-weight: bold;
      font-size: 1.8rem;
    }
    p {
      color: var(--text);
      font-family: var(--font-body);
      font-size: 1.4rem;
      line-height: 1.5;
      margin: 1rem 0;
    }
    .button {
      background: var(--primary);
      color: var(--background);
      display: inline-block;
      font-size: 1.4rem;
      font-weight: bold;
      margin-top: 1.5rem;
      padding: 1rem 1.4rem;
      text-decoration: none;
      text-transform: uppercase;
      a {
        &:hover {
          filter: brightness(1.2);
        }
      }
    }
    .image-wrapper {
      margin-left: 8rem;
    }
  }
  .header {
    align-items: center;
    background: rgba(0,0,0,0.8) url('https://webcdn.hirezstudios.com/esports/assets/header-what-is.png') center center / cover no-repeat;
    background-blend-mode: overlay;
    display: flex;
    flex-direction: column;
    height: 27.5rem;
    justify-content: center;
    .sub-heading,
    .heading {
      color: white;
      font-family: var(--font-body);
      font-size: 2.4rem;
      letter-spacing: .24rem;
      text-align: center;
      text-transform: uppercase;
    }
    .heading {
      display: block;
      font-size: 6rem;
      font-weight: bold;
      margin-top: 1rem;
      @media screen and (max-width: 768px) {
        font-size: 4rem;
      }
    }
    .links-wrapper {
      display: flex;
      margin-top: 3rem;
      a {
        background: var(--primary);
        color: var(--background);
        cursor: pointer;
        display: inline-block;
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0 .5rem;
        padding: 1rem 1.4rem;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
          filter: brightness(1.2);
        }
      }
    }
  }
  .about,
  .watch {
    display: flex;
    @media screen and (max-width: 768px) {
      align-items: center;
      flex-direction: column-reverse;
      padding: 3rem !important;
      .image-wrapper {
        margin-bottom: 2rem;
        margin-left: initial;
      }
    }
  }
  .watch {
    .copy-wrapper {
      strong {
        color: var(--text);
        display: block;
        font-weight: bold;
        margin: 2rem 0;
        text-transform: uppercase;
      }
      .schedule-container {
        color: var(--text);
        strong {
          display: inline-block;
          line-height: 1.5;
          margin: initial;
          text-transform: initial;
          width: 12rem;
        }
      }
      a {
        text-decoration:underline;
        &:hover {
          filter: brightness(1.2);
          text-decoration:underline;
        }
      }
    }
  }
  .format,
  .teams {
    @media screen and (max-width: 768px) {
      padding: 3rem !important;
    }
    .phase-container{
      color: var(--text);
      font-family: var(--font-body);
      font-size: 1.4rem;
      line-height: 1.5;
      margin: 1rem 0;
      strong{
        display:inline;
        text-transform: none;
        &:nth-of-type(1) {
          font-size: 1.4rem;
        }
      }
      
      
    }
    strong {
      color: var(--text);
      display: block;
      font-size: 1.4rem;
      font-weight: bold;
      text-transform: uppercase;
      &:nth-of-type(1) {
        font-size: 1.8rem;
      }
      &:not(:nth-of-type(1)) {
        margin-top: 3rem;
      }
    }
  }
  .teams {
    .teams-container {
      border: .1rem solid var(--border);
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      @media screen and (max-width: 768px) {
        flex-wrap: nowrap;
        flex-direction: column;
      }
      .team-heading {
        background: var(--primary);
        box-sizing: border-box;
        color: var(--background);
        font-weight: bold;
        padding: .9rem 0 .9rem 1.9rem;
        text-transform: uppercase;
        width: 100%;
      }
      .team {
        align-items: center;
        background-color: var(--background);
        border-bottom: .1rem solid var(--border);
        box-sizing: border-box;
        color: var(--text);
        font-size: 1.8rem;
        display: flex;
        font-weight: bold;
        margin-top: 0;
        padding: 1rem 2rem;
        text-decoration: none;
        text-transform: uppercase;
        width: 50%;
        @media screen and (max-width: 768px) {
          border-right: initial !important;
          border-bottom: .1rem solid var(--border);
          width: 100%;
        }
        img {
          margin-right: 1.2rem;
          max-width: 5rem;
        }
        &:nth-of-type(odd) {
          border-right: .1rem solid var(--border);
        }
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
`;

export default PageWrapper;